import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class BuyerGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromRoot.getUserState).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        if (AuthenticationService.canBuyOffers('PIA', userState)
              || AuthenticationService.canBuyOffers('ALL_UC', userState)
              || AuthenticationService.canBuyOffers('DIN_BIL', userState)
              || AuthenticationService.canBuyOffers('PB', userState)
              || AuthenticationService.canBuyOffers('VGRX', userState)) {
          return true;
        } else {
          // redirect to VehicleMaintenanceGuard which will handle further processing, since user is not a buyer
          this.router.navigate(['/maintenance/vehicle']);
          return false;
        }
      }));
  }
}
